import { Grid, Typography } from '@emburse/embark';

export function DescriptionRow(props: { description: string }): JSX.Element {
  const { description } = props;
  return (
    <Grid container direction="row" spacing={5} wrap="wrap">
      <Grid item>
        <Typography variant="h6">{description}</Typography>
      </Grid>
    </Grid>
  );
}
