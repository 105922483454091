import { useParams } from 'react-router';
import { ServerDataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import { PER_PAGE_OPTIONS, USER_PROFILES_PER_PAGE } from '../../constants';
import { yesNoFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedUserProfile } from '../../types/api/serialized_user_profile';
import { useApi, usePagination } from '../../hooks';
import { listUserProfilesForCompany } from '../../api/routes';
import { ISerializedCompany } from '../../types/api/serialized_company';
import { displayYesNo } from '../../helpers/text_formatters';

const userColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 320,
    renderCell: function render(params: CellParams) {
      const userProfileId = params.getValue('id') as string;
      const companyId = params.getValue('company_id') as string;
      const userId = params.getValue('user_id') as string;

      return <Link to={`/companies/${companyId}/users/${userId}/user_profiles/${userProfileId}`}>{userProfileId}</Link>;
    }
  },
  { field: 'company_id', hide: true },
  { field: 'user_id', hide: true },
  {
    field: 'company',
    headerName: 'Company',
    sortable: false,
    renderCell: function render(params: CellParams) {
      const company = params.getValue('company') as ISerializedCompany;

      return <>{company?.name || ''}</>;
    }
  },
  { field: 'state', headerName: 'State', width: 100, sortable: false },
  { field: 'role', headerName: 'Role', width: 100, sortable: false },
  { field: 'manages', headerName: 'Manages', width: 100, valueFormatter: yesNoFormatter, sortable: false },
  { field: 'home_currency', headerName: 'Home currency', width: 150, sortable: false },
  {
    field: 'process_reimbursements_through_abacus',
    headerName: 'Processes reimbursements through Abacus',
    width: 300,
    valueFormatter: yesNoFormatter,
    sortable: false
  },
  {
    field: 'admin_approval_permission',
    headerName: 'Final Approval Permission',
    width: 200,
    valueGetter: ({ value, row }: { value: any, row: any}) => {
      if (row.role !== 'admin') {
        return 'IS NOT ADMIN';
      } 

      const has_approval_permission = !(value && value.access_type === 'user_profile.non_approval');
      return displayYesNo(has_approval_permission);
    },
  }
];

interface PageRouteParams {
  companyId: string;
  userId: string;
}

function UserProfileList() {
  const { companyId, userId } = useParams<PageRouteParams>();
  const { limit, offset, onPageChange } = usePagination(USER_PROFILES_PER_PAGE);
  const { items, loading } = useApi<ISerializedUserProfile>(listUserProfilesForCompany(companyId, userId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={userColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { UserProfileList };
