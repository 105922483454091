import { ServerDataGrid } from '@emburse/embark';

import { ITEMS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { useApi, usePagination } from '../../hooks';
import { listSFTPImportLogs } from '../../api/routes';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedSFTPImportLog } from '../../types/api/serialized_sftp_import_log';
import { SortItem } from '@material-ui/data-grid';

const expenseRuleColumns = [
  { field: 'id', hide: true },
  { field: 'created_at', headerName: 'Created On', type: 'dateTime', flex: 1, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'filename', headerName: 'File name', flex: 1, sortable: false },
  { field: 'state', headerName: 'Processing state', flex: 1, sortable: false },
  { field: 'message', headerName: 'Message', flex: 1, sortable: false }
];

type ComponentProps = {
  companyId: string;
};

const SFTPImportLogs = ({ companyId }: ComponentProps) => {
  const { limit, offset, onPageChange } = usePagination(ITEMS_PER_PAGE);
  const { items, loading } = useApi<ISerializedSFTPImportLog>(listSFTPImportLogs(companyId, limit, offset));

  const defaultSortModel: SortItem[] = [
    {
      field: 'created_at',
      sort: 'desc'
    }
  ];

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      pageSize={limit}
      columns={expenseRuleColumns}
      autoHeight={true}
      loading={loading}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      onPageChange={onPageChange}
      defaultSortModel={defaultSortModel}
    />
  );
};

export { SFTPImportLogs };
