import { useEffect, useState, ChangeEvent } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

type TabItem = {
  label: React.ReactNode | string,
  uri?: string;
}

export function useTabs(tabs: TabItem[]) {
  const location = useLocation();
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const urlPostfix = location.pathname.split('/').pop();

    tabs.forEach(({ uri }, index) => {
      if (uri && uri === urlPostfix) {
        setTabValue(index);
      }
    });
  }, [tabs, location]);

  // Default tab change without url change
  const onTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  // If we want to change url on tab click, use this handler with "uri" property as a link
  const onLinkedTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    history.push(tabs[newValue]?.uri || '/');
    setTabValue(newValue);
  };

  return { tabValue, onTabChange, onLinkedTabChange };
}
