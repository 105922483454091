import { SortItem } from '@material-ui/data-grid';

export type listCompanyRequestFilterType = {
  name?: string;
  emailAddress?: string;
  state?: string;
};

export const companyRequestBasePath = '/company_requests';

export const getDecoratedCompanyRequestsPath = (
  limit: number,
  offset: number,
  order: SortItem,
  whereQuery?: listCompanyRequestFilterType
) => {
  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&sortField=${order.field}&sortDirection=${order.sort}`;
  }
  if (whereQuery?.emailAddress) {
    query += `&email_address=${whereQuery.emailAddress}`;
  }

  if (whereQuery?.name) {
    query += `&company_name=${whereQuery.name}`;
  }

  if (whereQuery?.state) {
    query += `&state=${whereQuery.state}`;
  }

  return `${companyRequestBasePath}${query}`;
};

export const getCompanyRequestByIdPath = (companyRequestId?: string) => `${companyRequestBasePath}/${companyRequestId}`;
