import qs from 'qs';


export const listFeatures = (limit: number, offset: number) => {
  const route = '/features';

  const query = qs.stringify({
    limit,
    offset
  })

  return `${route}?${query}`;
};
