export type ListTransactionsWhere = {
  state?: string;
  original_vendor?: string;
}

export const listCardProgramsForCompany = (companyId: string, limit = 0, offset = 0) => {
  const route = `/companies/${companyId}/card_programs`;
  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
};

export const getCardProgramForCompany = (companyId: string, cardProgramId: string) => {
  return `/companies/${companyId}/card_programs/${cardProgramId}?include[]=subaccounts&include[]=company`;
};

export const listCardsForProgramAndCompany = (companyId: string, cardProgramId: string, limit = 0, offset = 0) => {
  const route = `/companies/${companyId}/card_programs/${cardProgramId}/cards`;
  const query = `?limit=${limit}&offset=${offset}&include[]=user&state=active`;

  return `${route}${query}`;
};

export const getCardForProgramAndCompany = (companyId: string, cardProgramId: string, cardId: string) => {
  return `/companies/${companyId}/card_programs/${cardProgramId}/cards/${cardId}?include[]=user&include[]=company`;
};

export const getCardIssuingDetails = (companyId: string, cardProgramId: string, cardId: string) => {
  return `/companies/${companyId}/card_programs/${cardProgramId}/cards/${cardId}/details`;
};

export const listTransactionsForProgramAndCompany = (companyId: string, cardProgramId: string, cardId: string, limit = 0, offset = 0, where: ListTransactionsWhere) => {
  const route = `/companies/${companyId}/card_programs/${cardProgramId}/cards/${cardId}/transactions`;
  let query = `?limit=${limit}&offset=${offset}`;

  if (where.state) {
    query += `&state=${where.state}`;
  }
  if (where.original_vendor) {
    query += `&original_vendor=${where.original_vendor}`;
  }

  return `${route}${query}`;
};
