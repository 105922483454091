import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellParams, SortItem } from '@material-ui/data-grid';
import { Button, Grid, ServerDataGrid, Tooltip, Typography } from '@emburse/embark';

import './CompanyRequestList.css';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { ISerializedCompanyRequest, COMPANY_REQUEST_STATE_TYPE, COMPANY_REQUEST_VALUES } from '../../types/api/serialized_company_request';
import { useApi, usePagination } from '../../hooks';
import { PER_PAGE_OPTIONS, COMPANY_REQUESTS_PER_PAGE } from '../../constants';
import { FilterBar } from '../Common/FilterBar';
import { Spacer } from '../Common/UI';
import { getDecoratedCompanyRequestsPath, listCompanyRequestFilterType } from '../../api/routes/company_requests';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';

const columns = [
  { field: 'id', hide: true },
  {
    field: 'company_name',
    headerName: 'Company Name',
    flex: 1,
    renderCell: function render(params: CellParams) {
      const companyRequestId = params.getValue('id') as string;
      return <Link to={`/company_requests/${companyRequestId}`}>{params.getValue('company_name')}</Link>;
    }
  },
  {
    field: 'initial_user_first_name',
    headerName: "Initial User' First Name",
    flex: 1
  },
  {
    field: 'initial_user_last_name',
    headerName: "Initial User' Last Name",
    flex: 1
  },
  {
    field: 'initial_user_email_address', headerName: 'Initial User\' Email Address', width: 240, sortable: false,
    renderCell: function render(params: any) {
      const email = params.getValue('initial_user_email_address') as string;
      return (
        <Tooltip title={email}><span>{email}</span></Tooltip>
      );
    }
  },
  {
    field: 'created_at',
    headerName: 'Created At Date',
    type: 'dateTime',
    width: 200,
    sortable: false,
    valueFormatter: dateTimeFormatter
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At Date',
    type: 'dateTime',
    width: 200,
    sortable: false,
    valueFormatter: dateTimeFormatter
  },
  { field: 'state', headerName: 'Request State', width: 140, sortable: false }
];

const stateOptions: { label: string; value: COMPANY_REQUEST_STATE_TYPE }[] = COMPANY_REQUEST_VALUES.map((value) => {
  return {
    label: value,
    value: value
  };
});

export function CompanyRequestList() {
  const navigate = useHistory();

  const [where, setWhere] = useState<listCompanyRequestFilterType>({});
  const [order, setOrder] = useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(COMPANY_REQUESTS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCompanyRequest>(getDecoratedCompanyRequestsPath(limit, offset, order, where));

  const handleSearch = (companyName: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, name: companyName });
  };

  const handleFilter = (stateQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, state: stateQuery });
  };

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };

  function goToCompanyRequestRegistration() {
    navigate.push('/company_requests/registration');
  }

  return (
    <div className="company-request-list">
      <Typography variant="h4">
        Company Requests
        <div className="request-registration-link">
          <Button
            id="request-registration-button"
            className="lowercase-embark-button"
            color="primary"
            size="small"
            onClick={goToCompanyRequestRegistration}
          >
            <span className="request-registration-label">Invite Company</span>
          </Button>
        </div>
      </Typography>

      <Grid container justify="space-between" spacing={2}>
        <Grid item>
          <SearchBar onSearch={handleSearch} label="Company Name" />
        </Grid>
        <Grid item>
          <FilterBar label="Filter by state" options={stateOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={columns}
        autoHeight={true}
        loading={loading}
        pageSize={limit}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      />
    </div>
  );
}
