import { createContext, useEffect, useContext } from 'react';
import { Alert, makeStyles } from '@emburse/embark';
import { useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { designToken } from '../../../designToken';

export type ApiError = { message?: string, statusText?: string, status?: number };

type ErrorAction = {
  showError: (error: ApiError, id?: string) => void,
  hideError: (id: string) => void,
  resetErrors: () => void
}

const ErrorDispatch = createContext<ErrorAction>({
  showError: () => {}, resetErrors: () => {}, hideError: () => {}
});

const useStyles = makeStyles({
  toastErrorContainer: {
    background: designToken.palette.error.main
  },
  toastErrorBody: {
    background: designToken.palette.error.main
  }
});

type ErrorHandlerProviderProps = { children: React.ReactNode };

const ErrorMessage = ({ title, message }: { title: string, message: string }) => (
  <Alert
    severity="error"
    variant="filled"
    title={title}
  >{message}</Alert>
);

const ErrorHandlerProvider = ({ children }: ErrorHandlerProviderProps) => {
  const location = useLocation();
  const classes = useStyles();

  const showError = (error: ApiError, id?: string) => {
    const title = `${error.status || ''} ${error.statusText || ''}`.trim();
    const message = error?.message || '';

    toast.error(<ErrorMessage title={title} message={message} />, {
      toastId: id || Math.random(),
      className: classes.toastErrorContainer,
      bodyClassName: classes.toastErrorBody
    });

    console.log(error); // eslint-disable-line no-console
  };

  const hideError = (id: string) => {
    toast.dismiss(id);
  };

  const resetErrors = () => {
    toast.dismiss();
  };

  useEffect(() => {
    toast.dismiss();
  }, [location]);

  return (
    <ErrorDispatch.Provider value={{ showError, hideError, resetErrors }}>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      {children}
    </ErrorDispatch.Provider>
  );
};

export const useError = () => useContext(ErrorDispatch);

export { ErrorHandlerProvider };
