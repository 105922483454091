import { CardInfo, CardProgramInfo } from '../components/Cards';
import { CompanyInfo, CompanyList } from '../components/Companies';
import { CompanyRequestInfoContainer, CompanyRequestCreationContainer, CompanyRequestList } from '../components/CompanyRequests';
import { CreditInfo } from '../components/Credits';
import { DebitInfo } from '../components/Debits';
import { ExpenseInfo } from '../components/Expenses';
import { FeatureFlagList } from '../components/Features/FeatureFlagList';
import { MainViews } from '../components/Navigation/MainViews';
import { UserProfileInfo } from '../components/UserProfiles';
import { UserInfo } from '../components/Users';
import { UserSearch } from '../components/Users/UserSearch';

export const mainViewRoute = { exact: false, path: '/', Component: MainViews, title: 'Home' }

export const routesForCompany = [
  {
    exact: true,
    path: '/companies/:companyId',
    Component: CompanyInfo,
    title: 'Company Info'
  }
];
export const routesForUsersInCompany = [
  {
    exact: true,
    path: '/companies/:companyId/users',
    Component: CompanyInfo,
    title: 'Users'
  },
  {
    exact: true,
    path: '/companies/:companyId/users/:userId',
    Component: UserInfo,
    title: 'User Info'
  },

  {
    exact: true,
    path: '/companies/:companyId/users/:userId/user_profiles',
    Component: UserInfo,
    title: 'User Profiles'
  },
  {
    exact: true,
    path: '/companies/:companyId/users/:userId/user_profiles/:userProfileId',
    Component: UserProfileInfo,
    title: 'Profile Info'
  },

  {
    exact: true,
    path: '/companies/:companyId/users/:userId/expenses',
    Component: UserInfo,
    title: 'Expenses'
  },
  {
    exact: true,
    path: '/companies/:companyId/users/:userId/expenses/:expenseId',
    Component: ExpenseInfo,
    title: 'Expense Info'
  },

  {
    exact: true,
    path: '/companies/:companyId/users/:userId/credits',
    Component: UserInfo,
    title: 'Credits'
  },
  {
    exact: true,
    path: '/companies/:companyId/users/:userId/credits/:creditId',
    Component: CreditInfo,
    title: 'Credit Info'
  }
];

export const routesForExpensesInCompany = [
  {
    exact: true,
    path: '/companies/:companyId/expenses',
    Component: CompanyInfo,
    title: 'Expenses'
  },
  {
    exact: true,
    path: '/companies/:companyId/expenses/:expenseId',
    Component: ExpenseInfo,
    title: 'Expense Info'
  }
];

export const routesForCardProgramsInCompany = [
  {
    exact: true,
    path: '/companies/:companyId/card_programs',
    Component: CompanyInfo,
    title: 'Card Programs'
  },
  {
    exact: true,
    path: '/companies/:companyId/card_programs/:cardProgramId',
    Component: CardProgramInfo,
    title: 'Card Program Info'
  },

  {
    exact: true,
    path: '/companies/:companyId/card_programs/:cardProgramId/cards',
    Component: CardProgramInfo,
    title: 'Cards'
  },
  {
    exact: true,
    path: '/companies/:companyId/card_programs/:cardProgramId/cards/:cardId',
    Component: CardInfo,
    title: 'Card Info'
  }
];

export const routesForCreditsInCompany = [
  {
    exact: true,
    path: '/companies/:companyId/credits',
    Component: CompanyInfo,
    title: 'Credits'
  },
  {
    exact: true,
    path: '/companies/:companyId/credits/:creditId',
    Component: CreditInfo,
    title: 'Credit Info'
  },
  {
    exact: true,
    path: '/companies/:companyId/credits/:creditId/expenses',
    Component: CreditInfo,
    title: 'Expenses'
  },
  {
    exact: true,
    path: '/companies/:companyId/credits/:creditId/expenses/:expenseId',
    Component: ExpenseInfo,
    title: 'Expense Info'
  }
];

export const routesForDebitsInCompany = [
  {
    exact: true,
    path: '/companies/:companyId/debits',
    Component: CompanyInfo,
    title: 'Debits'
  },
  {
    exact: true,
    path: '/companies/:companyId/debits/:debitId',
    Component: DebitInfo,
    title: 'Debit Info'
  },
  {
    exact: true,
    path: '/companies/:companyId/debits/:debitId/expenses',
    Component: DebitInfo,
    title: 'Expenses'
  },
  {
    exact: true,
    path: '/companies/:companyId/debits/:debitId/expenses/:expenseId',
    Component: ExpenseInfo,
    title: 'Expense Info'
  }
];

export const routesForCompanyRequests = [
  {
    exact: true,
    path: '/company_requests/registration',
    Component: CompanyRequestCreationContainer,
    title: 'Company Request Registration'
  },
  { exact: true, path: '/company_requests/:companyRequestId', Component: CompanyRequestInfoContainer, title: 'Request Info' }
];

export const routesForMainView = [
  {
    exact: true,
    path: '/companies',
    Component: CompanyList,
    title: 'All Companies'
  },
  {
    exact: true,
    path: '/company_request',
    Component: CompanyRequestList,
    title: 'Invited Companies'
  },
  {
    exact: true,
    path: '/user_search',
    Component: UserSearch,
    title: 'User Search'
  },
  {
    exact: true,
    path: '/features',
    Component: FeatureFlagList,
    title: 'Feature List'
  },
]