import { Tabs } from '@emburse/embark';

import { useTabs } from '../../hooks';
import { Route, Switch } from 'react-router-dom';
import { routesForMainView } from '../../api/route_paths';

const mainTabs = [
  { label: 'Companies', uri: 'companies' },
  { label: 'Invited Companies', uri: 'company_request' },
  { label: 'User Search', uri: 'user_search' },
  { label: 'Feature List', uri: 'features' }
];

function MainViews() {
  const { tabValue, onLinkedTabChange } = useTabs(mainTabs);

  return (
    <>
      <Tabs id="main-view-tabs" key={tabValue} variant="scrollable" tabs={mainTabs} initialIndex={tabValue} onChange={onLinkedTabChange} />

      <Switch>
        {routesForMainView.map(({ path, Component, exact }) => (
          <Route key={path} path={path} exact={exact}>
            <Component />
          </Route>
        ))}
      </Switch>
    </>
  );
}

export { MainViews };
