import { Grid, TextField } from '@emburse/embark';
import { DescriptionRow } from '.';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../../types/api/serialized_company_request';

interface ICompanyRequestAddressInfoProps {
  companyRequest: ISerializedCompanyRequest;
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
}

export function CompanyRequestAddressInfo(props: ICompanyRequestAddressInfoProps): JSX.Element {
  const { companyRequest, onChange } = props;
  return (
    <>
      <DescriptionRow description="Street Address" />
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={3}>
          <TextField
            id="street_address"
            type="text"
            value={companyRequest.street_address}
            label="Street Address"
            onChange={(event: any) => onChange(event.target.value, 'street_address')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            id="address_city"
            type="text"
            value={companyRequest.address_city}
            label="City"
            onChange={(event: any) => onChange(event.target.value, 'address_city')}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            id="address_state"
            type="text"
            value={companyRequest.address_state}
            label="State"
            onChange={(event: any) => onChange(event.target.value, 'address_state')}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            id="address_country_code"
            type="text"
            value={companyRequest.address_country_code}
            label="Country Code"
            onChange={(event: any) => onChange(event.target.value, 'address_country_code')}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            id="postal_code"
            type="text"
            value={companyRequest.postal_code}
            label="Postal Code"
            onChange={(event: any) => onChange(event.target.value, 'postal_code')}
          />
        </Grid>
      </Grid>
    </>
  );
}
