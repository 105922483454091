import { useParams } from 'react-router';
import { Card, CurrencyFormattedDisplay, Grid, TabPanel, Tabs, Typography } from '@emburse/embark';

import { ISerializedExpense } from '../../types/api/serialized_expense';
import { displayDateTime, displayYesNo } from '../../helpers/text_formatters';
import { useApi, useTabs } from '../../hooks';
import { getExpenseForCompany } from '../../api/routes';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { Loader } from '../Common/UI';
import { SyncRecord } from '../SyncRecord';
import { ExpenseActivityList, RulesList, ExpenseTagsList } from '.';

interface PageRouteParams {
  companyId: string,
  userId?: string,
  expenseId: string
}

const expenseTabs = [
  { label: 'Activities' },
  { label: 'Rules' },
  { label: 'Custom Fields' }
];

function ExpenseInfo() {
  const { companyId, userId, expenseId } = useParams<PageRouteParams>();
  const { item, loading } = useApi<ISerializedExpense>(getExpenseForCompany(companyId, expenseId));
  const { tabValue, onTabChange } = useTabs(expenseTabs);

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no expense</div>;
  }

  const {
    vendor,
    note,
    amount,
    user_currency_code,
    company_currency_code,
    user,
    company,
    current_reviewer,
    created_at,
    date,
    state,
    resubmittable,
    reimbursement_method,
    source,
    sync_status,
    sync_record,
    expense_rule_warnings_string,
    expense_tags,
    category
  } = item;

  return (
    <>
      <Typography variant="h4">
        {note}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Expense Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Vendor" value={vendor}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title=" Expense amount">
              <CurrencyFormattedDisplay amount={amount / 100} code={user_currency_code || company_currency_code} />
            </InfoBoxDataItem>
          </Grid>
          {user &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Submitter" value={user.name}/>
          </Grid>
          }
          {company &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company" value={company.name}/>
          </Grid>
          }
          {current_reviewer &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Current Reviewer" value={current_reviewer.name}/>
          </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Created date" value={displayDateTime(created_at)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Submitted date" value={displayDateTime(date)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Resubmittable" value={displayYesNo(resubmittable)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Reimbursement Method" value={reimbursement_method}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Category" value={category?.name || '-'}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Source" value={source}/>
          </Grid>
          {sync_status &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Accounting sync" value={sync_status}/>
          </Grid>
          }
        </Grid>
        {sync_record && <SyncRecord syncRecord={sync_record} />}
      </Card>
      <Tabs id="expense-tab-bar"
        tabs={expenseTabs}
        onChange={onTabChange}
        initialIndex={tabValue}
      />
      <TabPanel value={tabValue} index={0}>
        <ExpenseActivityList companyId={companyId} userId={userId} expenseId={expenseId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {expense_rule_warnings_string && <RulesList rulesString={expense_rule_warnings_string} />}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ExpenseTagsList expenseTags={expense_tags} />
      </TabPanel>
    </>
  );
}

export { ExpenseInfo };
