import { Button, ButtonContainer, Card, Grid, Typography } from '@emburse/embark';

import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { CompanyRequestUserInfo, CompanyRequestAddressInfo, DescriptionRow, CompanyRequestDetails } from './subComponents';
import { CompanyRequestSignUpInfo } from './subComponents/CompanyRequestSignUpInfo/CompanyRequestSignUpInfo';

interface ICompanyRequestInfoComponentProps {
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
  onUpdate: () => Promise<void>;
  onResendInvitation: () => Promise<void>;
  companyRequest: ISerializedCompanyRequest;
  isSubmitting?: boolean;
}

export function CompanyRequestInfoComponent(props: ICompanyRequestInfoComponentProps): JSX.Element {
  const { onChange, onUpdate, onResendInvitation, companyRequest, isSubmitting } = props;

  if (!companyRequest) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h4">{companyRequest.company_name}</Typography>
      <Card className="card-block">
        <CompanyRequestDetails companyRequest={companyRequest} />
        <DescriptionRow description="Company Request" />
        <CompanyRequestSignUpInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestAddressInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestUserInfo companyRequest={companyRequest} onChange={onChange} />
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <ButtonContainer alignButtons={'right'}>
              <Button onClick={onResendInvitation} disabled={isSubmitting}>Resend Invite</Button>
              <Button onClick={onUpdate}  disabled={isSubmitting}>Save Changes</Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
