import { ISerializedUser } from './serialized_user';

export interface ISerializedCard {
  id: string;
  user_id: string;
  company_id: string;
  name: string;
  state: 'active' | 'canceled' | 'error' | 'deleted' | 'paused' | 'suspended' | 'disconnected' | 'pending' | 'unactivated';
  type: 'corporate_card' | 'personal_card' | 'iav';
  card_type: 'PHYSICAL' | 'DIGITAL';
  is_virtual_card: boolean;
  number: string;
  accounting_integration_account_id?: string;
  last4: string;
  user?: ISerializedUser;
  company?: ISerializedUser;
}

export enum CARD_TYPES {
  PHYSICAL = 'Physical',
  DIGITAL = 'Digital',
  VIRTUAL = 'Virtual'
}
