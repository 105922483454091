import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { CompanyRequestInfoComponent } from './CompanyRequestInfoComponent';
import { CompanyRequestNotFound } from './subComponents';
import { Loader } from '../Common/UI';
import { useError } from '../Common/ErrorHandler';
import { useApi } from '../../hooks';
import { IRequestOptionsWithBody, GetAuthorizedApiService } from '../../api';
import { getCompanyRequestByIdPath } from '../../api/routes/company_requests';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { toast } from 'react-toastify';

export function CompanyRequestInfoContainer() {
  const { companyRequestId } = useParams<{ companyRequestId: string }>();
  const companyRequestByIdPath = getCompanyRequestByIdPath(companyRequestId);
  const { showError } = useError();

  const { item: requestFromDB, loading: loadingRequests } = useApi<ISerializedCompanyRequest>(companyRequestByIdPath);

  const { makePutRequest, makePostRequest } = GetAuthorizedApiService();

  const [requestState, updateRequestState] = useState(requestFromDB);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // when we get the request from the DB/request from the DB changes update the requestState we use to fill the form fields,
  useEffect(() => updateRequestState(requestFromDB), [requestFromDB]);

  if (loadingRequests) {
    return <Loader />;
  }

  if (!requestFromDB || !requestState) {
    return CompanyRequestNotFound();
  }

  // event triggers
  function onChange(value: any, field: ISerializedCompanyRequestKeys, nestedKey?: string) {
    if (!requestState) {
      return;
    }
    let updatedCompanyRequest;
    if (field === 'request_options' && nestedKey) {
      const oldOptions = requestState.request_options || {};
      updatedCompanyRequest = {
        ...requestState,
        request_options: {
          ...oldOptions,
          [nestedKey]: value
        }
      };
    } else {
      updatedCompanyRequest = {
        ...requestState,
        [field]: value
      };
    }

    updateRequestState(updatedCompanyRequest);
  }

  async function updateCompanyRequest() {
    try {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      const putOptions: IRequestOptionsWithBody = {
        body: JSON.stringify(requestState)
      };
      await makePutRequest(companyRequestByIdPath, putOptions);
      toast.success('Updated company request!');
    } catch (error: any) {
      showError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function resendInvitation() {
    try {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      await makePostRequest(`${companyRequestByIdPath}/invite`);
      toast.success('Resent!');
    } catch (error: any) {
      showError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CompanyRequestInfoComponent
      onChange={onChange}
      onUpdate={updateCompanyRequest}
      onResendInvitation={resendInvitation}
      companyRequest={requestState}
      isSubmitting={isSubmitting}
    />
  );
}
