import { Typography, Card, Grid, Button, ButtonContainer } from '@emburse/embark';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { CompanyRequestAddressInfo, CompanyRequestUserInfo, DescriptionRow } from './subComponents';
import { CompanyRequestSignUpInfo } from './subComponents/CompanyRequestSignUpInfo/CompanyRequestSignUpInfo';

interface ICompanyRequestCreationComponentProps {
  companyRequest: ISerializedCompanyRequest;
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
  createCompanyRequest: any;
  onCancel: any;
  isSubmitting?: boolean;
}

export function CompanyRequestCreationComponent(props: ICompanyRequestCreationComponentProps): JSX.Element {
  const { companyRequest, onChange, createCompanyRequest, onCancel, isSubmitting } = props;
  if (!companyRequest) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h4">Company Request Registration</Typography>
      <Card className="card-block">
        <DescriptionRow description="Company Request Information" />
        <CompanyRequestSignUpInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestAddressInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestUserInfo companyRequest={companyRequest} onChange={onChange} />
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <ButtonContainer alignButtons={'right'}>
              <Button onClick={onCancel} disabled={isSubmitting}>Cancel</Button>
              <Button onClick={createCompanyRequest} disabled={isSubmitting}>Create Request</Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
