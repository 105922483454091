import { Breadcrumbs } from '../Navigation';

import { createContext, useContext, useState } from 'react';
import { ISerializedCompany } from '../../types/api/serialized_company';
import { ISerializedUser } from '../../types/api/serialized_user';

export type BreadcrumbTitles = { [key: string]: string };

const BreadcrumbsContext = createContext<React.Dispatch<BreadcrumbTitles>>(() => null);

type BreadcrumbsProviderProps = { children: React.ReactNode };

type ApiGenericItemType = {
  id?: string;
  name?: string;
  company_name?: string;
  company?: ISerializedCompany;
  user?: ISerializedUser;
};

const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [titles, setTitles] = useState<BreadcrumbTitles>({});

  const setBreadcrumbs = (item: ApiGenericItemType) => {
    const incomingTitles: { [key: string]: string } = {};
    if(!item) {
      return ''
    }

    // Check for incoming api item's name and set if different than existing one
    if (item.id) {
      const itemName = item.name || item.company_name;
      if (itemName && titles[item.id] !== itemName) {
        incomingTitles[item.id] = itemName;
      }
    }
    if (item.user && titles[item.user.id] !== item.user.name) {
      incomingTitles[item.user.id] = item.user.name;
    }
    if (item.company && titles[item.company.id] !== item.company.name) {
      incomingTitles[item.company.id] = item.company.name;
    }

    // Only rerender when new titles were set
    if (Object.keys(incomingTitles).length) {
      setTitles({ ...titles, ...incomingTitles });
    }
  };

  return (
    <BreadcrumbsContext.Provider value={setBreadcrumbs}>
      <Breadcrumbs cachedTitles={titles} />
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
export { BreadcrumbsProvider, BreadcrumbsContext };
