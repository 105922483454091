import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CellParams } from '@material-ui/data-grid';
import { Grid, ServerDataGrid, Typography } from '@emburse/embark';

import { SearchBar } from '../../Common/SearchBar/SearchBar';
import {
  ISerializedCompanyRequest
} from '../../../types/api/serialized_company_request';
import { useApi, usePagination } from '../../../hooks';
import { PER_PAGE_OPTIONS, COMPANY_REQUESTS_PER_PAGE } from '../../../constants';
import { Spacer } from '../../Common/UI';
import { QueryOptions, searchUserCompanies } from '../../../api/routes/users';

interface ISearchCompany {
  id: string;
  name: string;
}

interface ISearchUser {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
}

const columns = [
  { field: 'id', hide: true },
  {
    field: 'full_name',
    headerName: 'Name',
    flex: 1,
    renderCell: function render(params: CellParams) {
      const user = params.getValue('user') as ISearchUser;
      const company = params.getValue('company') as ISearchCompany;
      const firstName = user.first_name;
      const lastName = user.last_name;
      const companyId = company.id;
      return <Link to={`/companies/${companyId}/users/${user.id}`}>{[firstName, lastName].join(' ')}</Link>;
    }
  },
  {
    field: 'email',
    headerName: 'User Email',
    flex: 1,
    valueGetter: function render(params: CellParams) {
      const user = params.getValue('user') as ISearchUser;
      return user.email;
    }
  },
  {
    field: 'company',
    headerName: 'Associated Company',
    flex: 1,
    renderCell: function render(params: CellParams) {
      const company = params.getValue('company') as ISearchCompany;
      const companyId = company.id;
      const companyName = company.name;
      return <Link to={`/companies/${companyId}`}>{companyName}</Link>;
    }
  }
];

export function UserSearch() {
  const [userQuery, setUserQuery] = useState<string>('');
  const [companyQuery, setCompanyQuery] = useState<string>('');
  const queryOptions: QueryOptions = {
    userQuery,
    companyQuery
  };
  const { limit, offset, onPageChange } = usePagination(COMPANY_REQUESTS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCompanyRequest>(searchUserCompanies(queryOptions, limit, offset));
  const handleUserSearch = (newSearchQuery: string) => {
    onPageChange(0, limit);
    setUserQuery(newSearchQuery);
  };

  const handleCompanySearch = (newSearchQuery: string) => {
    onPageChange(0, limit);
    setCompanyQuery(newSearchQuery);
  };
  return (
    <div className="company-request-list">
      <Typography variant="h4">User Search</Typography>

      <Grid container justify="flex-start" spacing={2}>
        <Grid item>
          <SearchBar onSearch={handleUserSearch} label="Name or Email" />
        </Grid>
        <Grid item>
          <SearchBar onSearch={handleCompanySearch} label="Company Name" />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={columns}
        autoHeight={true}
        loading={loading}
        pageSize={limit}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        onPageChange={onPageChange}
      />
    </div>
  );
}
