import { Grid } from '@emburse/embark';
import { DescriptionRow } from '.';
import { displayDateTime } from '../../../helpers/text_formatters';
import { ISerializedCompanyRequest } from '../../../types/api/serialized_company_request';
import { InfoBoxDataItem } from '../../Common/InfoBoxDataItem';

export function CompanyRequestDetails(props: { companyRequest: ISerializedCompanyRequest }): JSX.Element {
  const { companyRequest } = props;
  return (
    <>
      <DescriptionRow description="Company Request Details" />
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={5}>
          <InfoBoxDataItem title="ID" value={companyRequest?.id} />
        </Grid>
        <Grid item xs={6} md={5}>
          <InfoBoxDataItem title="Partner Id" value={companyRequest?.partner_unique_id} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={5}>
          <InfoBoxDataItem title="created at" value={displayDateTime(companyRequest?.created_at as number)} />
        </Grid>
        <Grid item xs={6} md={5}>
          <InfoBoxDataItem title="updated at" value={displayDateTime(companyRequest?.updated_at as number)} />
        </Grid>
        <Grid item xs={6} md={2}>
          <InfoBoxDataItem title="State" value={companyRequest?.state} />
        </Grid>
      </Grid>
    </>
  );
}
