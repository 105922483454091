import { useParams } from 'react-router';
import { Card, Tabs, TabPanel, Grid, Typography } from '@emburse/embark';

import { DebitList } from '../Debits';
import { CompanyCreditList } from '../Credits';
import { UserList } from '../Users';
import { CardProgramList } from '../Cards';
import { SyncErrorList, CompanyFeatureList } from '.';
import { ISerializedCompany } from '../../types/api/serialized_company';
import { useApi, useTabs } from '../../hooks';
import { getCompany } from '../../api/routes';
import { displayDateTime, displayYesNo } from '../../helpers/text_formatters';
import { ApprovalRouteList, ExpenseRuleList, CompanyExpenseTagBucketList } from '.';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { Loader } from '../Common/UI';
import { GLAccountList } from './GLAccountList';
import { CategoryList } from './CategoryList';
import { CompanyExpenseList } from '../Expenses/CompanyExpenseList';
import { SFTPImportLogs } from './SFTPImportLog';

const companyTabs = [
  { label: 'Users', uri: 'users' },
  { label: 'Credits', uri: 'credits' },
  { label: 'Debits', uri: 'debits' },
  { label: 'Expenses', uri: 'expenses' },
  { label: 'Card Programs', uri: 'card_programs' },
  { label: 'Sync Errors' },
  { label: 'Custom Fields' },
  { label: 'Approval Routes' },
  { label: 'Expense Rules' },
  { label: 'GL Accounts' },
  { label: 'Categories' },
  { label: 'Features' },
  { label: 'SFTP Import Logs' }
];

interface PageRouteParams {
  companyId: string;
}

function CompanyInfo() {
  const { companyId } = useParams<PageRouteParams>();
  const { item, loading } = useApi<ISerializedCompany>(getCompany(companyId));
  const { tabValue, onTabChange } = useTabs(companyTabs);

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no item</div>;
  }

  const {
    id,
    name,
    street_address,
    address_city,
    address_state,
    postal_code,
    phone_number,
    website,
    state,
    has_connected_bank,
    bank_verified,
    accounting_connection,
    accounting_auto_sync,
    payout_schedule,
    authentication_method,
    created_at,
    currency_code,
    assigned_manager_precedes_approval_routes,
    external_company_id_from_whitelabel
  } = item;

  return (
    <>
      <Typography variant="h4">{name}</Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">Company Information</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Address" value={`${street_address}, ${address_city}, ${address_state} ${postal_code}`} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Emburse Company ID" value={id} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Phone number" value={phone_number} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Website" value={website} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company Bank account status" value={has_connected_bank ? 'Connected' : 'Not connected'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Verification Status" value={displayYesNo(bank_verified)} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Accounting Sync" value={accounting_connection?.service_name || accounting_connection?.service || '-'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Manual Accounting Sync Review Enabled (auto sync disabled)" value={displayYesNo(!accounting_auto_sync)} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Payout Schedule" value={payout_schedule} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Authentication Method" value={authentication_method} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Joined On" value={displayDateTime(created_at)} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Manager precedes approval routers" value={displayYesNo(assigned_manager_precedes_approval_routes)} />
          </Grid>
          {external_company_id_from_whitelabel && (
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="SVB CIF" value={external_company_id_from_whitelabel} />
            </Grid>
          )}
          {accounting_connection && (
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Accounting Method" value={accounting_connection.sync_mode || '-'} />
            </Grid>
          )}
        </Grid>
      </Card>

      <Tabs id="company-tab-bar" tabs={companyTabs} variant="scrollable" onChange={onTabChange} initialIndex={tabValue} />
      <TabPanel value={tabValue} index={0}>
        <UserList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CompanyCreditList companyId={companyId} currency={currency_code} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DebitList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <CompanyExpenseList currency={currency_code} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <CardProgramList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <SyncErrorList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <CompanyExpenseTagBucketList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <ApprovalRouteList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <ExpenseRuleList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={9}>
        <GLAccountList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={10}>
        <CategoryList companyId={companyId} />
      </TabPanel>
      <TabPanel value={tabValue} index={11}>
        <CompanyFeatureList featuresData={item} />
      </TabPanel>
      <TabPanel value={tabValue} index={12}>
        <SFTPImportLogs companyId={companyId} />
      </TabPanel>
    </>
  );
}

export { CompanyInfo };
