import { useParams } from 'react-router';
import { Card, Grid, Typography, Tabs, TabPanel, CurrencyFormattedDisplay } from '@emburse/embark';

import { useApi, useTabs } from '../../hooks';
import { getCardForProgramAndCompany, getCardIssuingDetails } from '../../api/routes';
import { CARD_TYPES, ISerializedCard } from '../../types/api/serialized_card';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { CardTransactionList } from '.';
import { Loader } from '../Common/UI';
import { ISerializedIssuingDetails } from '../../types/api/serialized_issuing_details';
import { maskCardNumber } from '../../helpers/text_formatters';

const cardTabs = [
  { label: 'Transactions' }
];

interface PageRouteParams {
  companyId: string,
  cardProgramId: string,
  cardId: string
}

function getCardType (card: ISerializedCard) {
  if (card.is_virtual_card) {
    return CARD_TYPES.VIRTUAL
  }

  if (card.card_type === 'DIGITAL') {
    return CARD_TYPES.DIGITAL
  }

  if (card.card_type === 'PHYSICAL') {
    return CARD_TYPES.PHYSICAL
  }
}

const CardInfo = () => {
  const { companyId, cardProgramId, cardId } = useParams<PageRouteParams>();

  const { item: card, loading: loadingCard } = useApi<ISerializedCard>(getCardForProgramAndCompany(companyId, cardProgramId, cardId));
  const { item: details, loading: loadingDetails } = useApi<ISerializedIssuingDetails>(getCardIssuingDetails(companyId, cardProgramId, cardId));

  const { tabValue, onTabChange } = useTabs(cardTabs);

  if (loadingCard || loadingDetails) {
    return <Loader />;
  }

  if (!card) {
    return <div>no item</div>;
  }

  const {
    last4,
    user,
    company,
    name,
    state,
    accounting_integration_account_id
  } = card;

  const currency = user?.currency_code || company?.currency_code || 'USD';
  const cardNumber = maskCardNumber(last4 || details?.last_four)

  return (
    <>
      <Typography variant="h4">
        {name}
      </Typography>
      <Card>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Card Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Card Type" value={getCardType(card)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Card Number" value={cardNumber}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Caldholder Name" value={user?.name}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Caldholder Email" value={user?.email}/>
          </Grid>

          {/* Issuing details */}
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Expiration Date" value={details?.expiration}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Availability Start Date" value={details?.allowance?.start_time}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Availability End Date" value={details?.allowance?.end_time}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Budget">
              {details?.allowance?.amount ? (
                <CurrencyFormattedDisplay amount={details.allowance.amount} code="USD" />
              ) : (
                null
              )}
            </InfoBoxDataItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Frequency" value={details?.allowance?.interval} />
          </Grid>
          
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Spent Amount">
              {(details?.allowance?.amount && details.allowance?.balance) ? (
                <CurrencyFormattedDisplay amount={details.allowance?.amount - details.allowance?.balance} code="USD" />
              ) : (
                null
              )}
            </InfoBoxDataItem>
          </Grid>

          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Remaining">
              {details?.allowance?.balance ? (
                <CurrencyFormattedDisplay amount={details.allowance?.balance} code="USD" />
              ) : (
                null
              )}
            </InfoBoxDataItem>
          </Grid>

          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          {company &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Company" value={company.name}/>
            </Grid>
          }
          {accounting_integration_account_id &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Accounting mapping ID" value={accounting_integration_account_id}/>
            </Grid>
          }
        </Grid>
      </Card>
      <Tabs
        id="cards-tab-bar"
        tabs={cardTabs}
        onChange={onTabChange}
        initialIndex={tabValue}
      />
      <TabPanel value={tabValue} index={0}>
        <CardTransactionList companyId={companyId} cardProgramId={cardProgramId} cardId={cardId} currency={currency} />
      </TabPanel>
    </>
  );
};

export { CardInfo };
