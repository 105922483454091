import { useState, useEffect, useCallback } from 'react';
import './Navigation.css';
import { TopNav, TopNavProps } from '@emburse/embark';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';

const organizationMap: any = {
  emburse: {
    logo: 'spend_logo.svg',
    name: 'Emburse'
  },
  svb: {
    logo: 'svb_logo.svg',
    name: 'SVB'
  }
}

const Navigation = () => {
  const [topNavProps, setTopNavProps] =  useState<TopNavProps>({});
  const { logout, user } = useAuth0();

  const handleLogout = useCallback(() => {
    const logoutOptions: LogoutOptions = { returnTo: window.location.origin };
    logout(logoutOptions);
  }, [logout]);
  
  useEffect(() => {
    const userSection = {
      sections: [{
        items: [{ iconText: 'Logout', onClick: handleLogout }]
      }]
    };

    const headerProps: TopNavProps = {};
    // user.organization_name is set in Auth0 actions
    // go to auth0 -> actions -> flows -> login
    const orgMetadata = organizationMap[user?.organization_name || 'svb'];

    headerProps.companyName = orgMetadata.name;
    headerProps.userName = user.name;
    headerProps.logoUrl = `${process.env.REACT_APP_APP_HOST}/${orgMetadata.logo}`;
    headerProps.homeUrl = process.env.REACT_APP_APP_HOST;
    headerProps.settingsMenu = userSection;
    setTopNavProps( headerProps );
  }, [handleLogout, user]);

  return (
    <TopNav {...topNavProps}/>
  );
};

export { Navigation };
