import { ServerDataGrid, Typography } from '@emburse/embark';

import { useApi, usePagination } from '../../hooks';
import { PER_PAGE_OPTIONS, FEATURE_FLAGS_PER_PAGE } from '../../constants';
import { Spacer } from '../Common/UI';
import { listFeatures } from '../../api/routes/features';
import { ISerializedFeatureFlag } from '../../types/api/serialized_feature_flag';

const columns = [
  { field: 'id', hide: true },
  {
    field: 'feature_name',
    headerName: 'Feature Name',
    flex: 1
  },
  { field: 'state', headerName: 'State', width: 140, sortable: false }
];

const FeatureFlagList = () => {
  const { limit, offset, onPageChange } = usePagination(FEATURE_FLAGS_PER_PAGE);
  const { items, loading } = useApi<ISerializedFeatureFlag>(listFeatures(limit, offset));

  return (
    <>
      <Typography variant="h4">
        Features
      </Typography>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={columns}
        autoHeight={true}
        loading={loading}
        pageSize={limit}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        onPageChange={onPageChange}
      />
    </>
  );
};

export { FeatureFlagList };
