import { Link } from 'react-router-dom';

export function CompanyRequestNotFound() {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>
        <div>Company Request not Found</div>
        <div>
          <Link to="/company_requests">Go back to Company Requests </Link>
        </div>
      </p>
    </div>
  );
}
